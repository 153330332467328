// app/javascript/controllers/glide_controller.js
import { Controller } from "stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect() {
    const glide = new Glide(this.element, {
      type: 'carousel',
      startAt: 0,      
      perView: 1,
      autoplay: 4000,
      animationDuration: 800
    });

    glide.on('mount.before', () => {
      document.getElementById('main-slider').style.background = this.fetchBackgroundColor(glide.index);
    });

    glide.on('mount.after', () => {
      document.getElementById('main-slider').style.background = this.fetchBackgroundColor(glide.index);
    });

    glide.on(['run', 'swipe.end'], () => {
      document.getElementById('main-slider').style.background = this.fetchBackgroundColor(glide.index);
    });

    glide.mount();
  }

  fetchBackgroundColor(activeIndex) {
    const slides = Array.from(this.element.querySelectorAll('.glide__slide:not(.glide__slide--clone)'));
    const activeSlide = slides[activeIndex];
    const backgroundColor = activeSlide ? activeSlide.style.background : '#E5E5E5';
    return backgroundColor;
  }
}